import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import yellow_star from "../../assets/images/png/yellow_star.png";
import user_icon from "../../assets/images/png/profile_icon.png";
import language_icon from "../../assets/images/png/language_icon.png";
import video_image from "../../assets/images/png/video_icon.png";
import line_1 from "../../assets/images/png/Group 2425.png";
import line_2 from "../../assets/images/png/line2.png";
import line_3 from "../../assets/images/png/line3.png";
import line_4 from "../../assets/images/png/line4.png";
import line_5 from "../../assets/images/png/line5.png";
import one_star from "../../assets/images/png/one_star.png";
import { FaArrowRight } from "react-icons/fa6";
import reviews_image5 from "../../assets/images/png/harshit_img.png";
import reviews_image6 from "../../assets/images/png/boy_img.png";
import reviews_image1 from "../../assets/images/png/see_image.png";
import star_image from "../../assets/images/png/star_image.png";
import enroll_right_icon from "../../assets/images/png/enroll_right_icon.png";
import { Accordion, Modal } from "react-bootstrap";
import { Circles } from "react-loader-spinner";
import { Parser } from "html-to-react";
import { ToastContainer, toast } from "react-toastify";
import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";
import makeApiCall from "../callApi";
import axios from "axios";
// import { initialize } from "../Payment";
const htmlParser = new Parser();

const CoursesDetailHero = ({ course, content }) => {
  console.log(course);
  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
  });

  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files?.length > 0 ? files[0] : value,
    }));
  };

  const createForm = async (e) => {
    e.preventDefault();

    const formdata = {
      name: formData?.name,
      email: formData?.email,
      phone: formData?.phone,
      topic: formData?.topic,
    };

    try {
      const res = await makeApiCall(
        formdata,
        "post",
        "new_lead",
        createFormCallBack,
        false
      );
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
    }
  };

  const createFormCallBack = useCallback((res) => {
    if (res.data.status === "success") {
      toast.success(res.data.message);
      setFreeTrialModalVisible(false);
      setFormData({ name: "", email: "", phone: "", topic: "" });
    } else {
      toast.error(res.data.message);
    }
  });

  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedData = localStorage.getItem("userData");
    if (storedData) {
      setUser(JSON.parse(storedData));
    }
  }, []);

  // ------------------------------------------------------------- PAYMNET GATEWAY START -------------------------------------------------------------
  // const PaymenthandleClick = async (e) => {
  //   e.preventDefault();

  //   const formdata = {
  //     name: user.name,
  //     amount: course?.price,
  //     user_id: user?._id,
  //   };

  //   try {
  //     const res = await makeApiCall(
  //       formdata,
  //       "post",
  //       // "ccEncrypt",
  //       "generate-payment-request",
  //       paymentCallBack,
  //       false
  //     );
  //   } catch (error) {
  //     toast.error("Failed to create lead. Please try again later.");
  //   }
  // };
  // const CC_ACCESS_CODE = "AVTO49MA99CI24OTIC";

  // const paymentCallBack = useCallback((res) => {
  //   if (res.data.status === "success") {
  //     console.log(res.data.data);

  //     const form = document.createElement("form");
  //     form.action =
  //       " https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
  //     form.method = "POST";
  //     form.style.display = "none";

  //     const input = document.createElement("input");
  //     input.type = "hidden";
  //     input.name = "encRequest";
  //     input.value = res.data.data;

  //     const accessCodeInput = document.createElement("input");
  //     accessCodeInput.type = "hidden";
  //     accessCodeInput.name = "access_code";
  //     accessCodeInput.value = CC_ACCESS_CODE;

  //     form.appendChild(input);
  //     form.appendChild(accessCodeInput);

  //     document.body.appendChild(form);
  //     form.submit();
  //   } else {
  //     toast.error(res.data.message);
  //   }
  // });

  const PaymenthandleClick = async (e) => {
    e.preventDefault();

    const formdata = {
      billingName: user.name,
      amount: course?.price,
      user_id: user?._id,
      billingEmail: "kodu@gmail.com",
      billingPhone: user.phone,
    };

    try {
      const res = await makeApiCall(
        formdata,
        "post",
        "generate-payment-request",
        paymentCallBack,
        false
      );
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
    }
  };

  const paymentCallBack = useCallback((res) => {
    console.log(res);
    if (res.status === 200) {
      console.log(res.data);

      const form = document.createElement("form");
      form.action =
        " https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction";
      form.method = "POST";
      form.style.display = "none";

      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "encRequest";
      input.value = res.data.encryptedData;

      const accessCodeInput = document.createElement("input");
      accessCodeInput.type = "hidden";
      accessCodeInput.name = "access_code";
      accessCodeInput.value = res.data.accessCode;

      form.appendChild(input);
      form.appendChild(accessCodeInput);

      document.body.appendChild(form);
      form.submit();
    } else {
      toast.error(res.data.message);
    }
  });

  // ------------------------------------------------------------- PAYMNET GATEWAY END -------------------------------------------------------------

  // Guard clause to ensure consistent hook calls
  // if (Object?.keys(course).length === 0) {
  //   return (
  //     <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
  //       <Circles
  //         height="80"
  //         width="80"
  //         color="#f4b621"
  //         ariaLabel="circles-loading"
  //         visible={true}
  //       />
  //     </div>
  //   );
  // }

  const htmlString = `${course?.description}`;
  const htmlString1 = content?.content?.map((item, index) => {
    return { index: index, description: item.description };
  });
  const parsedDesc = htmlParser.parse(htmlString);
  const parsedDesc1 = htmlParser.parse(htmlString1);
  const discountedPrice = course?.price - course?.discount;

  // ------------------------------------------------------------------------------------------------------------------------------------

  return (
    <div className=" d-flex flex-column min-vh-100 justify-content-center">
      <ToastContainer />
      <div>
        <Navbar />
      </div>
      <div className="container mt-5 mb-5 mb-lg-0 d-flex flex-column justify-content-start flex-grow-1">
        <div className="row">
          <div className="col-lg-8">
            <p className="fs_17 ff_heebo color_darkgray">
              <span className=" color_blue ">Our Coding Courses/</span>
              {course?.title}
            </p>
            <div className=" bg_darkblue p-sm-5 p-4 details_box mt-5 position-relative">
              {/* <img
                className="d-md-block d-none position-absolute custom_class"
                src={enroll_right_icon}
                alt="enroll_right_icon"
              /> */}
              <h3 className=" fs_26 fw-bold ff_heebo text-white">
                {course?.title}
              </h3>
              <p className=" fs_15 fw-normal ff_heebo color_lightwhite">
                {course?.sort_description}
              </p>
              <div className="d-sm-flex align-items-center gap-2">
                <img src={yellow_star} alt="yellow_star" />
                <p className="mb-0 mt-2 mt-sm-0 fs_15 ff_heebo fw-normal color_lightwhite">
                  4.54/5.0, 222 ratings
                </p>
              </div>
              <div className="d-sm-flex mt-5 gap-5 justify-content-between align-items-center">
                <div className="d-flex gap-3 align-items-center">
                  <img
                    width={70}
                    src={`https://api.koduapp.com/public/images/${course?.trainer_id?.image}`}
                    alt="hello"
                  />
                  <div>
                    <p className="mb-1 fs_15 ff_heebo fw-normal color_lightwhite">
                      Instructors
                    </p>
                    <h5 className="mb-0 fs_22 ff_heebo fw-bolder text-white">
                      {course?.trainer_id?.name}
                    </h5>
                  </div>
                </div>
                <div>
                  <div className="language_box mt-4 mt-sm-0  rounded-2">
                    {/* <h4 className=" fs_22 fw-bolder mt-2 ff_heebo color_yellow mb-0">
                      {course.technology}
                    </h4> */}
                    <div className=" bg_light  border-2 rounded-2 p-2 text-white">
                      <div className="d-flex align-items-center gap-2">
                        <img src={language_icon} alt="language_icon" />
                        <p className="mb-0 fs_15 ff_heebo fw-normal  text-white">
                          Language
                        </p>
                      </div>
                      {course?.technology?.map((obj, i) => {
                        return (
                          <>
                            <strong
                              key={i}
                              className=" color_yellow"
                              value="{course.technology}"
                            >
                              / {obj}
                            </strong>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" bg_lightyellow table_content mt-5 p-md-5 rounded-2">
              <h4 className=" fs_26 fw-bold ff_heebo color_yellow mb-0">
                Course Summary
              </h4>
              <div
                className=" fs_15 ff_heebo fw-normal color_darkgray mt-3 overflow-x-auto"
                style={{ maxWidth: "1300px" }}
              >
                {parsedDesc}
              </div>
            </div>
            <div className="bg_lightyellow mt-5 p-4 rounded-2">
              <div className="row">
                <div className="col-xl-4 col-lg-6">
                  <h5 className=" fs_17 fw-bold ff_heebo color_darkgray">
                    Student Feedback
                  </h5>
                  <h3 className=" mb-0 mt-2 fs_43 fw-bold ff_heebo color_blue">
                    4.5
                    <span className=" fs_17 ff_heebo fw-bold color_blue">
                      /5.0
                    </span>
                  </h3>
                  <img src={yellow_star} alt="hello" />
                </div>
                <div className="col-xl-4 mt-4 mt-xl-1 col-lg-6 ">
                  <button className=" bg_darkblue px-2 py-1 border-0 text-white rounded-1 ff_heebo fw-medium">
                    222 Ratings
                  </button>
                  <div className="d-sm-flex d-none align-items-center gap-2 mt-4">
                    <img src={line_1} alt="line_1" />
                    <p className="mb-0 fs_12 d-flex align-items-center gap-1 ff_heebo color_darkgray">
                      <span> 4</span>
                      <span>
                        <img src={one_star} alt="one_star" />
                      </span>
                    </p>
                  </div>
                  <div className="d-sm-flex d-none align-items-center gap-2 mt-1">
                    <img src={line_2} alt="line_1" />
                    <p className="mb-0 fs_12 d-flex align-items-center gap-1 ff_heebo color_darkgray">
                      <span> 3</span>
                      <span>
                        <img src={one_star} alt="one_star" />
                      </span>
                    </p>
                  </div>
                  <div className="d-sm-flex d-none align-items-center gap-2 mt-1">
                    <img src={line_3} alt="line_1" />
                    <p className="mb-0 fs_12 d-flex align-items-center gap-1 ff_heebo color_darkgray">
                      <span> 5</span>
                      <span>
                        <img src={one_star} alt="one_star" />
                      </span>
                    </p>
                  </div>
                  <div className="d-sm-flex d-none align-items-center gap-2 mt-1">
                    <img src={line_4} alt="line_1" />
                    <p className="mb-0 fs_12 d-flex align-items-center gap-1 ff_heebo color_darkgray">
                      <span> 7</span>
                      <span>
                        <img src={one_star} alt="one_star" />
                      </span>
                    </p>
                  </div>
                  <div className="d-sm-flex d-none align-items-center gap-2 mt-1">
                    <img src={line_5} alt="line_1" />
                    <p className="mb-0 fs_12 d-flex align-items-center gap-1 ff_heebo color_darkgray">
                      <span> 5</span>
                      <span>
                        <img src={one_star} alt="one_star" />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 mt-4 mt-xl-0">
                  <div className="d-flex justify-content-md-end justify-content-start">
                    <p className=" fs_15 ff_heebo color_darkgray">
                      View All <FaArrowRight />
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-sm-6 mt-4">
                  <div className="bg-white  bg_lightyellow h-100 rounded-2 p-4 yellow_bottom_border">
                    <div className="d-flex gap-3">
                      <img
                        width={50}
                        className="rounded-5"
                        src={reviews_image5}
                        alt="reviews_image4"
                      />
                      <div>
                        <h4 className="mb-0 fs_22 ff_heebo color_lightblack fw-medium">
                          Harshit
                        </h4>
                        <img src={star_image} alt="star_image" />
                      </div>
                    </div>
                    <p className=" mt-4 fs_14 ff_heebo color_darkgray">
                      As a polytechnic college graduate, I was seeking a way to
                      enhance my skills and open doors in the tech industry.
                      Enrolling in Kodu was one of the best decisions I've made.
                      Their practical approach to teaching and hands-on projects
                      allowed me to apply what I learned immediately. Today, I'm
                      proud to say that I'm thriving in a tech role, all thanks
                      to Seema mam. You are best.
                    </p>
                  </div>
                </div>
                <div className=" col-sm-6 mt-4">
                  <div className="bg-white  bg_lightyellow h-100 rounded-2 p-4 yellow_bottom_border">
                    <div className="d-flex gap-3">
                      <img
                        width={50}
                        className="rounded-5"
                        src={reviews_image6}
                        alt="reviews_image4"
                      />
                      <div>
                        <h4 className="mb-0 fs_22 ff_heebo color_lightblack fw-medium">
                          Rajveer
                        </h4>
                        <img src={star_image} alt="star_image" />
                      </div>
                    </div>
                    <p className=" mt-4 fs_14 ff_heebo color_darkgray">
                      The interview preparation and personality development
                      classes at Kodu are second to none. Their comprehensive
                      training not only sharpened my technical skills but also
                      equipped me with the confidence and professionalism needed
                      to excel in interviews. The mock interviews were
                      particularly helpful in boosting my confidence and
                      refining my communication skills. I'm grateful to Kodu for
                      preparing me thoroughly for the job market.
                    </p>
                  </div>
                </div>
                <div className="col-xl-6 mt-4">
                  <div className=" position-relative">
                    <img className="w-100" src={reviews_image1} alt="image" />
                    <div className="d-flex align-items-center position-absolute bottom-0 mb-4 justify-content-between w-100 px-lg-4 px-2">
                      <h4 className=" fs_17 mb-0 fw-medium text-white ff_heebo">
                        Nandini Mishra
                      </h4>
                      <a
                        href="https://youtube.com/shorts/6GyBYclK9fs"
                        target="_blanck"
                      >
                        <button className="py-2 border-0 px-4 fs_15 rounded-2 fw-medium text-black">
                          See Video
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5 pb-4 bg_lightyellow rounded-3">
              <div className=" bg_darkyellow px-4 py-3 rounded-top-3">
                <h3 className=" fs_26 ff_heebo fw-bold text-white mb-0">
                  Course Content
                </h3>
              </div>
              <div className="px-4 mt-3">
                <Accordion defaultActiveKey="0">
                  {content?.content?.map((item, key) => (
                    <Accordion.Item
                      key={key}
                      eventKey={key.toString()}
                      className="mt-3"
                    >
                      <Accordion.Header>
                        <h4 className="fs_20 mb-0 fw-bold ff_heebo">
                          {item.title}
                        </h4>
                      </Accordion.Header>
                      <div className="border"></div>
                      <Accordion.Body className="px-4 pb-3 mt-2">
                        {/* Conditionally render the description based on the active key */}
                        {htmlString1[key] && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: htmlString1[key].description,
                            }}
                          />
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="bg_darkblue p-4 my-5 rounded-2">
              <div className="d-flex gap-4 align-items-center">
                <img
                  width={70}
                  src={`https://api.koduapp.com/public/images/${course?.trainer_id?.image}`}
                  alt="hello"
                />
                <div>
                  <p className="mb-0 text-white fw-bolder ff_heebo ">
                    Instructors
                  </p>
                  <h3 className=" text-white fw-bolder ff_heebo mb-0">
                    {course?.trainer_id?.name}
                  </h3>
                </div>
              </div>
              <h4 className=" mt-4 ff_heebo fw-bold fs_26 text-white">
                About Mentors
              </h4>
              <p className=" fs_15 ff_heebo fw-normal text-white">
                {course?.trainer_id?.about}
              </p>
            </div>
          </div>
          <div className="col-lg-4 position-sticky mt-0 pt-4 ps-md-5 h-100 overflow-y-auto custom_top_space">
            <img className="w-100" src={video_image} alt="video_image" />
            <div className="Trial_border p-sm-4 p-3 mt-5">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h3 className=" fs_17 fw-normal ff_heebo color_yellow mb-0">
                    Take Free Trial
                  </h3>
                  <p className=" fs_15 ff_heebo mb-0 fw-normal color_darkgray mt-1">
                    Preview this course for free
                  </p>
                </div>
                <button
                  onClick={toggleFreeTrialModal}
                  className=" Free_btn border-0 px-sm-4 px-3 fs_17 ff_heebo fw-medium text-white"
                >
                  Free Trial
                </button>
              </div>
            </div>
            <div className="BuyNow_btn p-sm-4 p-3 mt-4 rounded-2">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className=" fs_15 text-decoration-line-through fw-normal ff_heebo color_darkgray  mb-0">
                    ₹{course?.discount}
                  </p>
                  {/* <h4 className=" fs_37 fw-bold ff_heebo mb-0 text-white">
                 ₹{discountedPrice}
                  </h4> */}
                  <h4 className=" fs_37 fw-bold ff_heebo mb-0 text-white">
                    ₹{course?.price}
                  </h4>
                  <p className=" fs_15 ff_heebo color_lightblack mb-0">
                    {course?.validity} months
                  </p>
                </div>
                <buttonq
                  onClick={PaymenthandleClick}
                  className="  bg-white border-0 px-4 fs_17 ff_heebo fw-medium color_yellow py-2 rounded-1"
                >
                  Enroll Now
                </buttonq>
              </div>
            </div>

            <form action="" onSubmit={createForm}>
              <div className="border my-4 p-4 border-2 rounded-2">
                <h4 className=" fs_17 ff_heebo fw-bold color_blue">
                  Book a free Counseling Session
                </h4>
                <input
                  required
                  value={formData?.name}
                  onChange={handleInputChange}
                  className=" bg_lightblue w-100 mt-3 py-2 px-3 rounded-2"
                  type="text"
                  name="name"
                  placeholder="Name"
                />
                <input
                  required
                  value={formData?.phone}
                  onChange={handleInputChange}
                  className=" bg_lightblue w-100 mt-3 py-2 px-3 rounded-2"
                  type="number"
                  name="phone"
                  placeholder="Mobile No"
                />
                <input
                  required
                  value={formData?.email}
                  onChange={handleInputChange}
                  className=" bg_lightblue w-100 mt-3 py-2 px-3 rounded-2"
                  type="email"
                  name="email"
                  placeholder="Email"
                />
                <input
                  required
                  value={formData?.topic}
                  onChange={handleInputChange}
                  className=" bg_lightblue w-100 mt-3 py-2 px-3 rounded-2"
                  type="text"
                  name="topic"
                  placeholder="Doubts"
                />
                <button className="w-100 py-2 bg_darkblue border-0 mt-4 rounded-1 text-white fw-semibold fs_17 ff_heebo">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        show={freeTrialModalVisible}
        centered
        onHide={toggleFreeTrialModal}
      >
        <div className="rounded-3 px-4 bg_darkblue position-relative pb-3">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFreeTrialModal}
              width={20}
              className="me-3 mt-3 cursor_pointer"
              src={crosswhtie_icon}
              alt="Close"
            />
          </div>
          <Modal.Header className="border-0 text-white pb-0 pt-5">
            <Modal.Title>
              <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-white">
                Free Trial Registration
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={createForm} action="">
              <div className="d-flex flex-column pt-0">
                <label
                  className=" fs_17 ff_heebo fw-medium text-white"
                  htmlFor=""
                >
                  Your Full Name
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="text"
                  placeholder="Enter Full Name"
                  value={formData?.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label
                  className=" fs_17 ff_heebo fw-medium text-white"
                  htmlFor=""
                >
                  Email
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData?.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label
                  className=" fs_17 ff_heebo fw-medium text-white"
                  htmlFor=""
                >
                  Contact Number
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="number"
                  placeholder="Enter contact number"
                  value={formData?.phone}
                  name="phone"
                  onChange={handleInputChange}
                />
              </div>
              <button
                type="submit"
                className="w-100 py-2 bg_yellow mt-5 text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
              >
                Submit
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};
export default CoursesDetailHero;
