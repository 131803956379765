import React from "react";
import logo from "../../assets/images/svg/logo.svg";
import facebook_icon from "../../assets/images/png/facebook_icon.png";
import insta_icon from "../../assets/images/png/insta_icon.png";
import in_icon from "../../assets/images/png/in_icon.png";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { MdOutlinePhoneInTalk } from "react-icons/md";

const Footer = () => {
  return (
    <div className="pt-5 pb-4 bg_darkblue">
      <div className="container">
        <div className=" mt-4 mt-md-0 pe-lg-5">
          <img className=" cursor_pointer" src={logo} alt="logo" />
          {/* <p className=" fs_17 mt-3 ff_heebo fw-normal text-white">
              Journey into the World of Coding: Learn, Innovate,<br className="d-xl-block d-none"></br> and Create with
              Our Comprehensive Platform
            </p>
             */}
        </div>
        <div className="row mt-4">
          <div className="custom_lg_col col-sm-4 col-6 pe-lg-5">
            <h4 className=" fs_22 ff_heebo fw-medium  color_yellow">
              Programs
            </h4>
            <ul className="ps-0">
              <li className="pt-2">
                <a
                  className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                  href="#"
                >
                  Classroom courses
                </a>
              </li>
              <li className="pt-2">
                <a
                  className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                  href="#"
                >
                  Live Online Classes
                </a>
              </li>
              <Link to="/recorded-courses">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Recorded Courses
                  </a>
                </li>
              </Link>
              <li className="pt-2">
                <a
                  className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                  href="#"
                >
                  Full Stack Development Course
                </a>
              </li>
              <li className="pt-2">
                <a
                  className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                  href="#"
                >
                  MERN Stack
                </a>
              </li>
              <Link to="/admin">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    admin
                  </a>
                </li>
              </Link>
            </ul>
          </div>
          <div className="custom_lg_col col-sm-4 col-6 ">
            <h4 className=" fs_22 ff_heebo fw-medium  color_yellow">
              Our Centres
            </h4>
            <ul className="ps-0">
              <Link to="/hisar-center">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Hisar
                  </a>
                </li>
              </Link>
              <Link to="/sirsa-center">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Sirsa
                  </a>
                </li>
              </Link>
              <Link to="/rohtak-center">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Rohtak
                  </a>
                </li>
              </Link>
              {/* <Link to="/jaipur-center">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Jaipur
                  </a>
                </li>
              </Link> */}
              <Link to="/sikar-center">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Sikar
                  </a>
                </li>
              </Link>
            </ul>
          </div>
          <div className="custom_lg_col col-sm-4 col-6 mt-4 mt-sm-0">
            <h4 className=" fs_22 ff_heebo fw-medium  color_yellow">Company</h4>
            <ul className="ps-0">
              <Link to="/contact-us-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Contact Us
                  </a>
                </li>
              </Link>
              <Link to="/course">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Courses
                  </a>
                </li>
              </Link>
              <Link to="/about-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    About Us
                  </a>
                </li>
              </Link>
              <Link to="/privacypolicy-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Privacy policy
                  </a>
                </li>
              </Link>
              <Link to="/terms-condition-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Terms & Condition's
                  </a>
                </li>
              </Link>
              <Link to="/refund-policy-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Refund Policy
                  </a>
                </li>
              </Link>
            </ul>
          </div>
          {/* <div className="custom_lg_col col-sm-4 col-12 pe-lg-5">
            <h5 className=" fs_22 ff_heebo text-white">Join Kodu</h5>
            <div className="d-flex custom_input rounded-2">
              <input
                className="w-100 bg-transparent px-2 text-white"
                type="text"
                placeholder="Your Email Address"
              />
              <button className=" bg_darkyellow px-4 py-2 border-0 text-white">
                <FaArrowRight />
              </button>
            </div>
            <p className=" fs_17 mt-3 ff_heebo fw-normal text-white">
              Get the latest news and updates right at your inbox.
            </p>
          </div> */}
          <div className="custom_lg_col col-sm-4 col-6 mt-4 mt-md-0 pe-lg-5">
            <h4 className=" fs_22 ff_heebo color_yellow">Resources</h4>
            <ul className="ps-0">
              <Link to="/scholarshipPage-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Scholarship
                  </a>
                </li>
              </Link>
              <Link to="/hire-from-us-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Hire From Us
                  </a>
                </li>
              </Link>
              <Link to="/blog-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Blogs
                  </a>
                </li>
              </Link>
              {/* <Link to="/webinars-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Upcoming Webinars
                  </a>
                </li>
              </Link> */}
              <Link to="/success-stories-page">
                <li className="pt-2">
                  <a
                    className="fs_17 ff_heebo fw-normal text-white after_line d-inline position-relative"
                    href="#"
                  >
                    Success Stories
                  </a>
                </li>
              </Link>
            </ul>
          </div>
          <div className="custom_lg_col col-sm-4 mt-4 mt-md-0 col-6 pe-lg-5">
            <h4 className=" fs_22 ff_heebo color_yellow">Contact</h4>
            <div className=" rounded-2 ">
              <div className=" pt-2">
                <p className=" mb-0 ff_heebo color_yellow"> Hisar, Haryana</p>
                <a
                  className=" text-white ff_heebo  pb-0"
                  href="tel:08950214425"
                >
                  <MdOutlinePhoneInTalk /> 08950214425
                </a>
              </div>
              <div className="custom_border my-2"></div>
              <div>
                <p className=" mb-0 ff_heebo color_yellow"> Sirsa, Haryana</p>
                <a
                  className=" text-white ff_heebo  pb-0"
                  href="tel:09138256355"
                >
                  <MdOutlinePhoneInTalk /> 091382 56355
                </a>
              </div>
              <div className="custom_border my-2"></div>
              <div>
                <p className=" mb-0 ff_heebo color_yellow"> Rohtak, Haryana</p>
                <a
                  className=" text-white ff_heebo  pb-0"
                  href="tel:+91 70151 44781"
                >
                  <MdOutlinePhoneInTalk /> +91 70151 44781
                </a>
              </div>
              <div className="custom_border my-2"></div>
              <div>
                <p className=" mb-0 ff_heebo color_yellow">
                  {" "}
                  Sikar, Rajasthan{" "}
                </p>
                <a
                  className=" text-white ff_heebo  pb-0"
                  href="tel:07737304499"
                >
                  <MdOutlinePhoneInTalk /> 077373-04499
                </a>
              </div>
              <div className="custom_border my-2"></div>
            </div>
          </div>
          <h6 className="mb-0 ff_heebo text-white mt-5">
            Kodu is a part of{" "}
            <span className=" color_yellow">
              Dhurina Ventures Private Limited
            </span>
          </h6>
        </div>
      </div>
      <div className="custom_border mt-4 mb-4"></div>
      <div className="container">
        <div className=" d-md-flex align-items-center  justify-content-between">
          <p className="text-center mb-0 text-white">
            Copyright © Kodu. All Rights Reserved
          </p>
          <p className="text-c color_yellow mt-2 text-center">
            MADE WITH ❤️ IN INDIA
          </p>
          <div className="d-flex justify-content-center justify-contnet-md-start gap-3 align-items-center mt-sm-3 mt-3 ">
            <a target="_blank" href="https://www.facebook.com/koduapp/">
              <img src={facebook_icon} alt="facebook_icon" />
            </a>
            <a target="_blank" href="https://www.instagram.com/kodubydhurina/">
              <img src={insta_icon} alt="facebook_icon" />
            </a>
            <a
              target="_blank"
              href="https://in.linkedin.com/company/kodu-coding-institute"
            >
              <img src={in_icon} alt="facebook_icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
